@tailwind base;
@tailwind components;
@tailwind utilities;

.kamburger span {
  width: 20px;
  height: 3px;
  background: white;
  display: flex;
  margin-bottom: 0.3rem;
}

.workitem1{background-image: url('./assets/portfolio-raquel.png');background-size: cover;}
.workitem2{background-image: url('./assets/portfolio-helpability.png');background-size: cover;}
.workitem3{background-image: url('./assets/portfolio-yerriah.png');background-size: cover;}
.workitem4{background-image: url('./assets/portfolio-pokemon.png');background-size: cover;}
.workitem5{background-image: url('./assets/portfolio-jessica.png');background-size: cover;}
.workitem6{background-image: url('./assets/portfolio-digitalblue.png');background-size: cover;}
